import axios from "axios";

const RESTAURANTS_BASE_URL =
  process.env.REACT_APP_API_BASE_URL + "/restaurants";
// const RESTAURANTS_BASE_URL = "http://localhost:3001/restaurants";

class RestaurantService {
  getRestaurantById(id) {
    return axios.get(RESTAURANTS_BASE_URL + `/restaurant/${id}`);
  }

  updateRestaurantAbout(restaurantId) {
    return axios.put(RESTAURANTS_BASE_URL + "/updateRestaurantAbout", {
      restaurantId,
    });
  }
}

export default new RestaurantService();
