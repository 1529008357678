import axios from "axios";

const ADMIN_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/restaurantAdmin";

class RestaurantAdminService {
  authenticate = (username, password) => {
    return axios.get(ADMIN_BASE_URL + "/authenticate", {
      auth: { username, password },
    });
  };
}

export default new RestaurantAdminService();
