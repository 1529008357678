import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactNotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import RestaurantAdminService from "services/RestaurantAdminService";
import PaidrezLogo from "../assets/img/paidrez-logo.png";

const Login = () => {
  const history = useHistory();
  const notificationAlertRef = React.useRef(null);

  const [restaurantId, setRestaurantId] = useState("");
  const [password, setPassword] = useState("");

  useLayoutEffect(() => {
    console.log("use layout effect");
    if (sessionStorage.getItem("adminSignedIn") === "true") {
      history.push("/admin/slots");
    }
  }, []);

  const notify = (message = <span>Something went wrong</span>) => {
    var options = {
      place: "bc",
      message: message,
      type: "danger",
      icon: "tim-icons  icon-alert-circle-exc",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    RestaurantAdminService.authenticate(restaurantId, password)
      .then((res) => {
        console.log(res);
        if (res.data.authenticated) {
          console.log("login successful");
          sessionStorage.setItem("adminSignedIn", true);
          sessionStorage.setItem("restaurantId", restaurantId);
          history.push("/admin/slots");
        } else {
          notify("Incorrect restaurant ID or password");
        }
      })
      .catch((e) => {
        console.error(e.response.data.message);
        notify();
      });
  };

  return (
    <div className="login">
      <img
        src={PaidrezLogo}
        alt="paidrez-logo"
        width={150}
        className="d-block mx-auto mt-3 mb-5"
      />

      <div className="react-notification-alert-container">
        <ReactNotificationAlert ref={notificationAlertRef} />
      </div>

      <h3 className="text-center ">Manage your restaurant on Paidrez</h3>

      <Form
        onSubmit={(e) => {
          handleSubmit(e);
          // notify();
        }}
      >
        <FormGroup>
          <Label className="" for="exampleEmail">
            Restaurant ID
          </Label>
          <Input
            className="bg-white text-darker"
            type="text"
            name="email"
            id="exampleEmail"
            value={restaurantId}
            onChange={(e) => setRestaurantId(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="" for="examplePassword">
            Password
          </Label>
          <Input
            className="bg-white text-darker"
            type="password"
            name="password"
            id="examplePassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>
        <Button
          className="w-100 mt-4 d-block"
          // type="submit"
          // onClick={() => {
          //   // sessionStorage.setItem("adminSignedIn", true);
          //   // history.push("/admin/slots");
          // }}
        >
          Login
        </Button>
        <div className="buttons-container d-flex justify-content-between">
          <Button className="btn-link" type="button">
            Need help?
          </Button>
          <Button className="btn-link" type="button">
            List your restaurant
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Login;
