import axios from "axios";

const SLOTS_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/slots";

class SlotsService {
  addSlot(restaurantId, datetime, slotCount, pax) {
    return axios.post(SLOTS_BASE_URL + "/addSlot", {
      restaurantId,
      datetime,
      slotCount,
      pax,
    });
  }

  getSlotsByDate(restaurantId, date) {
    return axios.get(SLOTS_BASE_URL + "/getSlotsByRestaurantId", {
      params: { restaurantId, date },
    });
  }

  getSlotCount(restaurantId, datetime) {
    return axios.get(SLOTS_BASE_URL + "/getSlotCount", {
      params: {
        restaurantId,
        datetime,
      },
    });
  }

  updateMultipleSlotsById(slots) {
    return axios.put(SLOTS_BASE_URL + "/updateMultipleSlotsById", { slots });
  }

  deleteSlot(slotId) {
    return axios.delete(SLOTS_BASE_URL + "/delete", { params: { slotId } });
  }

  deleteSlotsByDateTime(restaurantId, datetime) {
    return axios.delete(SLOTS_BASE_URL + "/deleteSlotsByDateTime", {
      params: { restaurantId, datetime },
    });
  }
}

export default new SlotsService();
