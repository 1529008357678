/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Dropdown,
} from "reactstrap";
import RestaurantService from "services/RestaurantService";

function UserProfile() {
  const [restaurantId] = useState(sessionStorage.getItem("restaurantId"));
  const [name, setName] = useState("");
  const [cusineType, setCuisineType] = useState("");
  const [priceIndicator, setPriceIndicator] = useState(2);
  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [locality, setLocality] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [website, setWebsite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [whyWeLikeIt, setWhyWeLikeIt] = useState("");
  const [needToKnow, setNeedToKnow] = useState("");
  const [about, setAbout] = useState("");

  useEffect(() => {
    RestaurantService.getRestaurantById(restaurantId).then((res) => {
      console.log(res.data);
      console.log(restaurantId);
      setName(res.data.restaurant.name);
      setCuisineType(res.data.restaurant.cuisine_type);
      setPriceIndicator(res.data.restaurant.price_indicator);
      setAddLine1(res.data.restaurant.line1);
      setAddLine2(res.data.restaurant.line2);
      setLocality(res.data.restaurant.locality);
      setCity(res.data.restaurant.city);
      setCountry(res.data.restaurant.country);
      setPostalCode(res.data.restaurant.zipcode);
      setEmail(res.data.restaurant.email);
      setMobile(res.data.restaurant.mobile);
      setWebsite(res.data.restaurant.website);
      setInstagram(res.data.restaurant.instagram);
      setFacebook(res.data.restaurant.facebook);
      setTwitter(res.data.restaurant.twitter);
      setWhyWeLikeIt(res.data.restaurant.whywelikeit);
      setNeedToKnow(res.data.restaurant.needtoknow);
      setAbout(res.data.restaurant.about);
    });
  }, []);

  useEffect(() => {
    console.log("Priceindicator:", priceIndicator);
  }, [priceIndicator]);

  const handleSubmit = () => {
    console.log("Submit button clicked");
    RestaurantService.updateRestaurantAbout(restaurantId);
  };

  return (
    <>
      <div className="content about">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h5 className="title">Edit Restaurant Details</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-md-1" md="5">
                      <FormGroup>
                        <label>Name</label>
                        <Input
                          placeholder="Restaurant Name"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1 pr-sm-1" xs="6" md="3">
                      <FormGroup>
                        <label>Cuisine Type</label>
                        <Input
                          placeholder="Cuisine Type"
                          type="text"
                          value={cusineType}
                          onChange={(e) => setCuisineType(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1 pl-sm-1" xs="6" md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Price Indicator
                        </label>
                        <Input
                          placeholder="price"
                          type="select"
                          defaultValue={priceIndicator}
                          onChange={(e) =>
                            setPriceIndicator(Number(e.target.value))
                          }
                        >
                          <option value="" hidden></option>
                          <option value={"1"}>$</option>
                          <option value={"2"}>$$</option>
                          <option value={"3"}>$$$</option>
                          <option value={"4"}>$$$$</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="6" lg="4">
                      <FormGroup>
                        <label>Address line 1</label>
                        <Input
                          // defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                          placeholder="Home Address"
                          type="text"
                          value={addLine1}
                          onChange={(e) => {
                            setAddLine1(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="6" lg="4">
                      <FormGroup>
                        <label>Address line 2</label>
                        <Input
                          // defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                          placeholder="Home Address"
                          type="text"
                          value={addLine2}
                          onChange={(e) => {
                            setAddLine2(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-lg-1" md="6" lg="4">
                      <FormGroup>
                        <label>Locality</label>
                        <Input
                          type="text"
                          value={locality}
                          onChange={(e) => {
                            setLocality(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>City</label>
                        <Input
                          type="text"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Country</label>
                        <Input
                          type="text"
                          value={country}
                          onChange={(e)=>setCountry(e.target.value)}
                        />
                      </FormGroup>
                    </Col> */}
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Postal Code</label>
                        <Input
                          type="number"
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <h3 className=" mt-2 subheading">Contact</h3>
                  <Row>
                    {/* <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                    </Col> */}
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Mobile</label>
                        <Input
                          type="text"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Website</label>
                        <Input
                          type="text"
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <h3 className=" mt-2 subheading">Social</h3>
                  <Row>
                    <Col className="pr-md-1" md="4">
                      <FormGroup>
                        <label>Instagram</label>
                        <Input
                          type="text"
                          value={instagram}
                          onChange={(e) => setInstagram(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="4">
                      <FormGroup>
                        <label>Facebook</label>
                        <Input
                          value={facebook}
                          onChange={(e) => setFacebook(e.target.value)}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="4">
                      <FormGroup>
                        <label>Twitter</label>
                        <Input
                          value={twitter}
                          onChange={(e) => setTwitter(e.target.value)}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <h3 className=" mt-2 subheading">Details</h3>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Why we like it</label>
                        <Input
                          type="textarea"
                          rows={8}
                          value={whyWeLikeIt}
                          onChange={(e) => setWhyWeLikeIt(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Need to know</label>
                        <Input
                          type="textarea"
                          rows={8}
                          value={needToKnow}
                          onChange={(e) => setNeedToKnow(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="12">
                      <FormGroup>
                        <label>About</label>
                        <Input
                          type="textarea"
                          rows={8}
                          value={about}
                          onChange={(e) => setAbout(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          cols="80"
                          defaultValue="Lamborghini Mercy, Your chick she so thirsty, I'm in
                            that two seat Lambo."
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-fill"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </CardFooter>
            </Card>
          </Col>
          {/* <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={require("assets/img/emilyz.jpg")}
                    />
                    <h5 className="title">Mike Andrew</h5>
                  </a>
                  <p className="description">Ceo/Co-Founder</p>
                </div>
                <div className="card-description">
                  Do not be scared of the truth because we need to restart the
                  human foundation in truth And I love you like Kanye loves
                  Kanye I love Rick Owens’ bed design but the back is...
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col> */}
        </Row>
      </div>
    </>
  );
}

export default UserProfile;
