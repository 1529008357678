import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  InputGroup,
  Input,
} from "reactstrap";

import DatePicker from "react-horizontal-datepicker";
import TimeInput from "react-time-picker-input";
import "react-time-picker-input/dist/components/TimeInput.css";
import SlotsService from "services/SlotsService";
import RestaurantService from "services/RestaurantService";

const TimeSlots = () => {
  // let array = [20, 20, 20, 20];

  // const [capacity, setCapacity] = useState(array);
  const [slots, setSlots] = useState([]);
  const [showNewSlot, setShowNewSlot] = useState(false);
  const [newTime, setNewTime] = useState("10:15");
  const [date, setDate] = useState("");
  const [newSlotsCounts, setNewSlotsCounts] = useState([]);
  const [changes, setChanges] = useState({});
  const [slotTypes, setSlotTypes] = useState(null);
  const [time, setTime] = useState([]);
  // const [restaurant, setRestaurant] = useState({});

  const restaurantId = sessionStorage.getItem("restaurantId");

  const fetchSlots = () => {
    console.log("fetchslotsfunction");
    console.log(date);
    setChanges({});

    SlotsService.getSlotsByDate(restaurantId, date)
      .then((res) => {
        console.log(res.data);
        setSlots(res.data.slots);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (date) fetchSlots();
    // console.log(date);
  }, [date]);

  useEffect(() => {
    RestaurantService.getRestaurantById(restaurantId)
      .then((res) => {
        console.log(res);
        let sTypes = res.data.restaurant.slot_types.split(",");
        setSlotTypes(sTypes);
        let arr = [];
        sTypes.forEach(() => {
          arr.push(0);
        });
        setNewSlotsCounts([...arr]);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    console.log("slots:", slots);
    let time = {};
    slots.forEach((slot) => {
      if (!time[slot.datetime]) {
        time[slot.datetime] = [
          {
            slots_id: slot.slots_id,
            slot_count: slot.slot_count,
            pax: slot.pax,
          },
        ];
      } else {
        time[slot.datetime].push({
          slots_id: slot.slots_id,
          slot_count: slot.slot_count,
          pax: slot.pax,
        });
      }
    });

    console.log(Object.entries(time));
    setTime([...Object.entries(time)]);
  }, [slots]);

  const selectedDay = (val) => {
    console.log("calling save changes from date change function");
    saveChanges();
    const date = ("0" + val.getDate()).slice(-2);
    const month = ("0" + (val.getMonth() + 1)).slice(-2);
    const year = val.getFullYear();
    const selectedDate = year + "-" + month + "-" + date;
    setDate(selectedDate);
  };

  const addNewSlot = () => {
    saveChanges();
    const datetime = date + "T" + newTime + ":00";
    // console.log(datetime);
    // console.log(newSlotsCounts);
    slotTypes.forEach((pax, i) => {
      SlotsService.addSlot(restaurantId, datetime, newSlotsCounts[i], pax)
        .then((res) => {
          console.log(res.data.message);
          fetchSlots(); // MIGHT NEED TO REMOVE THIS. AND WAIT FOR WHOLE LOOP EXECUTION TO COMPLETE
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const deleteSlot = (datetime) => {
    if (
      window.confirm(
        "Are you sure to delete slot for" + datetime.substring(11, 16)
      )
    ) {
      console.log("ok clicked");
      saveChanges();
      SlotsService.deleteSlotsByDateTime(restaurantId, datetime)
        .then((res) => {
          console.log(res);
          fetchSlots();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log("cancelled");
    }
  };

  const saveChanges = () => {
    console.log(changes);
    const ids = Object.keys(changes);

    let slotsChanged = [];
    ids.forEach((id) => {
      slotsChanged.push({ slotId: id, slotCount: changes[id] });
    });

    console.log(slotsChanged);

    if (slotsChanged.length) {
      SlotsService.updateMultipleSlotsById(slotsChanged)
        .then((res) => {
          console.log(res.data);
          setChanges([]);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleChanges = (id, value, slotCount) => {
    console.log("changes before:", changes);
    console.log("id:", id);
    let a = slots;
    if (slotCount) {
      a.forEach((slot) => {
        if (slot.slots_id === id) {
          slot.slot_count = slotCount + value;
        }
      });
      setChanges({ ...changes, [id]: slotCount + value });
    } else {
      a.forEach((slot) => {
        if (slot.slots_id === id) {
          slot.slot_count = value;
        }
      });
      setChanges({ ...changes, [id]: value });
    }

    setSlots([...a]);
  };

  return (
    <div className="content time-slots">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h3" className="text-center text-bold">
                Time Slots
              </CardTitle>
              <DatePicker
                getSelectedDay={selectedDay}
                labelFormat={"MMMM"}
                // color="rgb(10,105,126)"
                color="#0c8ba7"
              />
            </CardHeader>
            <CardBody>
              {slotTypes && (
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Pax</th>
                      {slotTypes.map((slotType, index) => (
                        <th>{slotType}</th>
                      ))}
                      {/* <th>2</th>
                      <th>4</th>
                      <th>6</th>
                      <th>8</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* {Array.from([1, 2, 3, 4]).map((_, i) => ( */}
                    {time.map((t, i) => (
                      <tr>
                        <td className="text-center time">
                          {/* <h4>14:{i * 15 === 0 ? "00" : i * 15}</h4> */}
                          <h4>{t[0].substring(11, 16)}</h4>
                        </td>

                        {t[1].map((slot) => (
                          <td className="text-center">
                            <InputGroup className="text-center number-of-slots-container">
                              <Input
                                type="number"
                                className="mx-2 text-center slot-count-input "
                                // value={capacity[i]}
                                value={slot.slot_count}
                                onChange={(e) =>
                                  handleChanges(
                                    slot.slots_id,
                                    Number(e.target.value)
                                  )
                                }
                              />
                              <div className="inc-dec-btn-container">
                                <Button
                                  className="btn-primary btn-sm inc-dec-btn"
                                  onClick={() =>
                                    handleChanges(
                                      slot.slots_id,
                                      1,
                                      slot.slot_count
                                    )
                                  }
                                >
                                  +
                                </Button>

                                <Button
                                  className="btn-primary btn-sm inc-dec-btn"
                                  onClick={() =>
                                    handleChanges(
                                      slot.slots_id,
                                      -1,
                                      slot.slot_count
                                    )
                                  }
                                >
                                  -
                                </Button>
                              </div>
                            </InputGroup>
                          </td>
                        ))}

                        <td>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 48 48"
                            width="24px"
                            height="24px"
                            onClick={() => deleteSlot(t[0].split(".")[0])}
                            className="delete-btn"
                          >
                            <path
                              fill="#F44336"
                              d="M21.5 4.5H26.501V43.5H21.5z"
                              transform="rotate(45.001 24 24)"
                            />
                            <path
                              fill="#F44336"
                              d="M21.5 4.5H26.5V43.501H21.5z"
                              transform="rotate(135.008 24 24)"
                            />
                          </svg>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
            {showNewSlot ? (
              <>
                <div className="text-center add-new-slot-container">
                  <h3 className="text-white">Add new time slot</h3>
                  <TimeInput
                    value={newTime}
                    hour24Format
                    eachInputDropdown
                    manuallyDisplayDropdown
                    onChange={(dateString) => setNewTime(dateString)}
                    className="mx-2"
                  />
                  {/* <Button
                    className="btn-primary btn-sm"
                    onClick={() =>
                      setNewSlotsCounts((newSlotCount) => newSlotCount - 1)
                    }
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    className="mx-2 text-center bg-white text-dark slot-count-input new-slot-count-input"
                    value={newSlotsCounts}
                    onChange={(e) => setNewSlotsCounts(Number(e.target.value))}
                  />
                  <Button
                    className="btn-primary btn-sm"
                    onClick={() =>
                      setNewSlotsCounts((newSlotCount) => newSlotCount + 1)
                    }
                  >
                    +
                  </Button> */}

                  <div className="new-slots-container">
                    {newSlotsCounts.map((count, i) => (
                      <InputGroup className="text-center number-of-slots-container">
                        <p className="text-white">{slotTypes[i]} pax : </p>
                        <Input
                          type="number"
                          className="mx-2 text-center slot-count-input bg-neutral"
                          // value={capacity[i]}
                          value={count}
                          onChange={(e) => {
                            let tempArr = newSlotsCounts;
                            tempArr[i] = Number(e.target.value);

                            setNewSlotsCounts([...tempArr]);
                          }}
                        />
                        <div className="inc-dec-btn-container">
                          <Button
                            className="btn-primary btn-sm inc-dec-btn"
                            onClick={() => {
                              let tempArr = newSlotsCounts;
                              tempArr[i] = tempArr[i] + 1;
                              setNewSlotsCounts([...tempArr]);
                            }}
                          >
                            +
                          </Button>

                          <Button
                            className="btn-primary btn-sm inc-dec-btn"
                            onClick={() => {
                              let tempArr = newSlotsCounts;
                              tempArr[i] = tempArr[i] - 1;
                              setNewSlotsCounts([...tempArr]);
                            }}
                          >
                            -
                          </Button>
                        </div>
                      </InputGroup>
                    ))}
                  </div>

                  <Col sm={12} className="mx-2">
                    <Button className="" onClick={addNewSlot}>
                      Save
                    </Button>
                    <Button
                      className="btn-danger"
                      onClick={() => setShowNewSlot(false)}
                    >
                      Cancel
                    </Button>
                  </Col>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center bottom-buttons-container">
                <Button
                  className="p-3 mx-3 btn-simple"
                  onClick={saveChanges}
                  // color="success"
                >
                  Save Changes
                </Button>
                <Button
                  className="p-3 btn-simple"
                  onClick={() => {
                    setShowNewSlot(true);
                    // setCapacity([...capacity, 0]);
                    // console.log(capacity);
                  }}
                  // color="success"
                >
                  Add new slot
                </Button>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TimeSlots;
