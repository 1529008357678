import axios from "axios";
import React from "react";
import RUG from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";

const initialState = [
  {
    name: "Restaurant",
    size: "232kb",
    source:
      "https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
    // "https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=4050&q=80",
  },
  {
    name: "Couch",
    size: "23kb",
    source:
      "https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
    // "https://images.unsplash.com/photo-1508923567004-3a6b8004f3d7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1834&q=80",
  },
  {
    name: "Window view",
    size: "222kb",
    source:
      "https://images.unsplash.com/photo-1554679665-f5537f187268?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
  },
  {
    name: "Bar",
    size: "222kb",
    source:
      "https://images.unsplash.com/photo-1514933651103-005eec06c04b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
  },
  {
    name: "Couch",
    size: "222kb",
    source:
      "https://images.unsplash.com/photo-1585518419759-7fe2e0fbf8a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1024&q=80",
  },
  {
    name: "Girl eating",
    size: "222kb",
    source:
      "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
];

const UploadImage = () => {
  const restaurantId = sessionStorage.getItem("restaurantId");
  const image_url = process.env.REACT_APP_API_BASE_URL + "/images/";
  const images = [{ source: image_url + restaurantId + ".jpeg" }];

  return (
    <div className="content">
      <RUG
        action={`${process.env.REACT_APP_API_BASE_URL}/restaurants/addRestaurantImage/${restaurantId}`}
        // source={(response) => `http://example.com/${response.source}`}
        source={(response) => image_url + response.source}
        initialState={images}
        // onConfirmDelete={(currentImage, images) => {
        onConfirmDelete={() => {
          return window.confirm("Are you sure you want to delete?");
        }}
        onDeleted={() => {
          axios.delete(
            process.env.REACT_APP_API_BASE_URL +
              "/restaurants/deleteRestaurantImage/" +
              restaurantId
          );
        }}
        onError={(response) => {
          console.log(response);
        }}
      />
    </div>
  );
};

export default UploadImage;
