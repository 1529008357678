/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import Rtl from "views/Rtl.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import About from "views/About";
import PastReservations from "views/PastReservations.js";
import TimeSlots from "views/TimeSlots";
import UploadImage from "views/UploadImage";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Upcoming reservations",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-bullet-list-67",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/pastReservations",
    name: "Past reservations",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-single-copy-04",
    component: PastReservations,
    layout: "/admin",
  },
  {
    path: "/slots",
    name: "Time Slots",
    // rtlName: "الرموز",
    icon: "tim-icons icon-watch-time",
    component: TimeSlots,
    layout: "/admin",
  },
  {
    path: "/uploadImage",
    name: "Restaurant Images",
    // rtlName: "الرموز",
    icon: "tim-icons icon-camera-18",
    component: UploadImage,
    layout: "/admin",
  },
  {
    path: "/about",
    name: "About restaurant",
    // rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: About,
    layout: "/admin",
  },

  {
    path: "/map",
    name: "Map",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    component: Map,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: "tim-icons icon-atom",
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: "tim-icons icon-bell-55",
    component: Notifications,
    layout: "/admin",
  },

  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: "tim-icons icon-align-center",
    component: Typography,
    layout: "/admin",
  },
  {
    path: "/rtl-support",
    name: "RTL Support",
    rtlName: "ار تي ال",
    icon: "tim-icons icon-world",
    component: Rtl,
    layout: "/rtl",
  },
];
export default routes;
