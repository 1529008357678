/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";

function Tables() {
  return (
    <>
      <div className="content">
        <Row>
          {/* <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h3" className="text-center text-bold">
                  PAST RESERVATIONS
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Time</th>
                      <th>Name</th>
                      <th>Contact</th>
                      <th className="text-center">Count</th>
                      <th className="text-center">Table Number</th>
                      <th>Bill amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from([1, 2, 3, 4, 5]).map((_, i) => (
                      <tr>
                        <td>14:00</td>
                        <td>Dakota Rice</td>
                        <td>9999999999</td>
                        <td className="text-center">
                          {Math.floor(2 + Math.random() * 5)}
                        </td>
                        <td className="text-center">
                          {17 + Math.floor(Math.random() * 10)}
                        </td>
                        <td>${Math.floor(Math.random() * 1000)}</td>
                      </tr>
                    ))}

                    {Array.from([1, 2, 3, 4, 5]).map((_, i) => (
                      <tr>
                        <td>14:15</td>
                        <td>Minerva Hooper</td>
                        <td>8912821828</td>
                        <td className="text-center">2</td>
                        <td>
                          <Button className="btn-danger">Cancel</Button>
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td>14:15</td>
                      <td>Sage Rodriguez</td>
                      <td>1234567890</td>
                      <td className="text-center">5</td>
                    </tr>
                    <tr>
                      <td>Philip Chaney</td>
                      <td>Korea, South</td>
                      <td>Overland Park</td>
                      <td className="text-center">$38,735</td>
                    </tr>
                    <tr>
                      <td>Doris Greene</td>
                      <td>Malawi</td>
                      <td>Feldkirchen in Kärnten</td>
                      <td className="text-center">$63,542</td>
                    </tr>
                    <tr>
                      <td>Mason Porter</td>
                      <td>Chile</td>
                      <td>Gloucester</td>
                      <td className="text-center">$78,615</td>
                    </tr>
                    <tr>
                      <td>Jon Porter</td>
                      <td>Portugal</td>
                      <td>Gloucester</td>
                      <td className="text-center">$98,615</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col> */}
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <CardTitle tag="h4">Booking history</CardTitle>
                {/* <p className="category">Here is a subtitle for this table</p> */}
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Time</th>
                      <th>Name</th>
                      <th>Contact</th>
                      <th className="text-center">Count</th>
                      <th className="text-center">Table Number</th>
                      <th className="text-center">Bill amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from([1, 2, 3, 4, 5]).map((_, i) => (
                      <tr>
                        <td>14:15</td>
                        <td>Dakota Rice</td>
                        <td>9999999999</td>
                        <td className="text-center">
                          {Math.floor(2 + Math.random() * 5)}
                        </td>
                        <td className="text-center">
                          {17 + Math.floor(Math.random() * 10)}
                        </td>
                        <td className="text-center">
                          ${Math.floor(Math.random() * 200)}
                        </td>
                      </tr>
                    ))}
                    {Array.from([1, 2, 3, 4, 5]).map((_, i) => (
                      <tr>
                        <td>14:00</td>
                        <td>Minerva Hooper</td>
                        <td>1239999999</td>
                        <td className="text-center">
                          {Math.floor(2 + Math.random() * 5)}
                        </td>
                        <td className="text-center">
                          {17 + Math.floor(Math.random() * 10)}
                        </td>
                        <td className="text-center">
                          ${Math.floor(Math.random() * 200)}
                        </td>
                      </tr>
                    ))}
                    {/* <tr>
                      <td>Dakota Rice</td>
                      <td>Niger</td>
                      <td>Oud-Turnhout</td>
                      <td className="text-center">$36,738</td>
                    </tr>
                    <tr>
                      <td>Minerva Hooper</td>
                      <td>Curaçao</td>
                      <td>Sinaai-Waas</td>
                      <td className="text-center">$23,789</td>
                    </tr>
                    <tr>
                      <td>Sage Rodriguez</td>
                      <td>Netherlands</td>
                      <td>Baileux</td>
                      <td className="text-center">$56,142</td>
                    </tr>
                    <tr>
                      <td>Philip Chaney</td>
                      <td>Korea, South</td>
                      <td>Overland Park</td>
                      <td className="text-center">$38,735</td>
                    </tr>
                    <tr>
                      <td>Doris Greene</td>
                      <td>Malawi</td>
                      <td>Feldkirchen in Kärnten</td>
                      <td className="text-center">$63,542</td>
                    </tr>
                    <tr>
                      <td>Mason Porter</td>
                      <td>Chile</td>
                      <td>Gloucester</td>
                      <td className="text-center">$78,615</td>
                    </tr>
                    <tr>
                      <td>Jon Porter</td>
                      <td>Portugal</td>
                      <td>Gloucester</td>
                      <td className="text-center">$98,615</td>
                    </tr> */}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
